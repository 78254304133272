import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { animated, useTransition, config } from "react-spring";
import { animated as oldAnimated } from "react-spring/renderprops";

const Slides = ({ photos }) => {
  const [index, set] = useState(0);
  const transitions = useTransition(photos[index], (item) => item.slug, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const nextImage = new Image();
  nextImage.src = `/perch/resources/${
    photos[(index + 1) % photos.length].fullSizePath
  }`;

  useEffect(() => {
    const timer = setInterval(
      () => set((state) => (state + 1) % photos.length),
      7000
    );

    return () => {
      window.clearInterval(timer);
    };
  });

  return transitions.map(({ item, props, key, photos }) => (
    <animated.div
      key={key}
      className="splashImage"
      item={item}
      style={{
        ...props,
        backgroundImage: `url(/perch/resources/${item.fullSizePath})`,
      }}
    />
  ));
};

const CreateSlides = (props) => {
  return (
    <Slides
      photos={props.photos.filter(
        (item) => item.splash && item.splash !== null
      )}
    />
  );
};

class Splash extends React.Component {
  firstImg = new Image();
  constructor(props) {
    super(props);

    this.state = {
      firstImage: null,
    };

    this.firstImg.src = `/perch/resources/${
      this.props.photos.filter((item) => item.splash && item.splash !== null)[0]
        .fullSizePath
    }`;
    this.firstImg.onload = () => {
      this.setState({ firstImage: true });
    };
  }
  render() {
    return (
      <oldAnimated.div style={this.props.style} id="hello">
        <Helmet>
          <title>Ross Brown. Photography/CGI Direction/Motion.</title>
        </Helmet>
        <Link to="/c/all">
          <div className="splashHolder">
            {this.state.firstImage ? (
              <CreateSlides photos={this.props.photos} />
            ) : null}
            <div className="splashName">Ross Brown.</div>
            <div className="splashLine">Photography.CGI Direction.Motion</div>
          </div>
        </Link>
      </oldAnimated.div>
    );
  }
}

export default Splash;
