import React from "react";
import { Helmet } from "react-helmet";
import { animated } from "react-spring/renderprops";

const Cgi = props => (
  <animated.div style={props.style}>
    <Helmet>
      <title>Ross Brown Photographer — CGI</title>
    </Helmet>
    <h1>CGI</h1>
  </animated.div>
);

export default Cgi;
