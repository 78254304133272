import React from "react";
import { Helmet } from "react-helmet";
import { animated } from "react-spring/renderprops";

const VimeoEmbed = ({ title, embed, aspect = "16/9" }) => (
  <div style={{ aspectRatio: aspect, position: "relative", marginBottom: 60 }}>
    <iframe
      title={title}
      src={`https://player.vimeo.com/video/${embed}?color=fffffff&title=0&byline=0&portrait=0`}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      frameBorder={0}
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen={true}
    />
  </div>
);

const Motion = ({ style }) => (
  <animated.div style={style}>
    <Helmet>
      <title>Ross Brown Photographer — Motion</title>
    </Helmet>
    {window.scrollTo(0, 0)}
    <h1>Motion</h1>

    <h2>Kohler</h2>
    <VimeoEmbed title="Kohler Poseidon" embed="764343716" aspect="1000/611" />

    <h2>Commonwealth Games</h2>
    <p>Creative Director and Photography.</p>
    <VimeoEmbed title="Commonwealth Games 60 sec" embed="324127925" />

    <h2>Royal New Zealand Ballet</h2>
    <p>Director</p>
    <p>Selected for the San Francisco Dance Film Festival 2024.</p>
    <VimeoEmbed title="I Deeply Know" embed="998975076" />
    <VimeoEmbed title="RNZB" embed="217617701" />
    <VimeoEmbed title="RNZB 2" embed="139179572" />

    <h2>ASB Business</h2>
    <p>
      <strong>The Caker.</strong> Direction/DOP
    </p>
    <VimeoEmbed title="ASB Caker" embed="324935560" />
    <p>
      <strong>Pic’s.</strong> Direction/DOP
    </p>
    <VimeoEmbed title="ASB Pics" embed="324936171" />

    <h2>Commonwealth Games Parallax</h2>
    <VimeoEmbed title="Commonwealth Games Boxer Animatic" embed="324127598" />
    <VimeoEmbed title="Commonwealth Games Javelin Animatic" embed="324127427" />
    <VimeoEmbed
      title="Commonwealth Games Gymnastics Animatic"
      embed="324127249"
    />
    <VimeoEmbed
      title="Commonwealth Games Swimming Animatic"
      embed="324127095"
    />
    <VimeoEmbed title="Commonwealth Games Runner Animatic" embed="324126957" />

    <script src="https://player.vimeo.com/api/player.js" />
  </animated.div>
);

export default Motion;
