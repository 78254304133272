import React from "react";
import { Helmet } from "react-helmet";
import { animated } from "react-spring/renderprops";

const Contact = (props) => (
  <animated.div style={props.style}>
    <Helmet>
      <title>Contact Ross Brown Photographer</title>
    </Helmet>
    <h1>Contact</h1>
    <h2>To contact Ross directly </h2>
    <p>
      email:{" "}
      <a href="mailto:ross@rossbrownphotographer.com">
        ross@rossbrownphotographer.com
      </a>
      <br />
      mob:&nbsp;&nbsp; +64 21.887.007{" "}
    </p>
    <h2>Represented in New Zealand &amp; Asia Pacific by</h2>
    <p>
      <strong>Match Photographers NZ</strong>
      <br />
      P.O. Box 47965 <br />
      Ponsonby
      <br />
      Auckland 1144
      <br />
      New Zealand
      <br />
      tel:&nbsp;+64 9.378.8572 <br />
      fax:&nbsp;+64 9.378.8923 <br />
      mob: +64 21.628.241 <br />
      <a href="mailto:gera%72din%65%40mat%63%68%70%68o%74ograp%68e%72s.com">
        gerardine@matchphotographers.com
      </a>
    </p>
    <h2>Represented in the USA and Americas by</h2>
    <p>
      <strong>Greenhouse Reps NYC</strong>
      <br />
      1501 Broadway
      <br />
      12th Floor
      <br />
      NYC, NY 10036
      <br />
      tel: +1 212.704.4300
      <br />
      fax: +1 212.704.4333
      <br />
      <a href="mailto:robin@g%72%65en%68ouserep%73.com">
        robin@greenhousereps.com
      </a>
      <br />
      <a href="mailto:g%61ry@g%72eenhouserep%73.co%6D">
        gary@greenhousereps.com
      </a>
    </p>
  </animated.div>
);

export default Contact;
