import React from "react";
//import { withSize } from "libreact/lib/SizeSensor";
import { NavLink, Link } from "react-router-dom";
import { animated } from "react-spring/renderprops";
import { Helmet } from "react-helmet";

const Filters = props => (
  <nav className="photoFilters">
    <ul>
      <li>
        <NavLink exact to="/c/all">
          All
        </NavLink>
      </li>

      <li>
        <NavLink to="/c/conceptual">Conceptual</NavLink>
      </li>
      <li>
        <NavLink to="/c/physical">Physical</NavLink>
      </li>
      <li>
        <NavLink to="/c/people">People</NavLink>
      </li>
      <li>
        <NavLink to="/c/land">Land</NavLink>
      </li>
      <li>
        <NavLink to="/c/objects">Objects</NavLink>
      </li>
      <li>
        <NavLink to="/c/comp">CGI & composite</NavLink>
      </li>
    </ul>
  </nav>
);

const filtered = ({ filter, photos }) =>
  !filter || filter === "All"
    ? photos
    : photos.filter(item => item.cats && item.cats.includes(filter));

const ImageHolder = props => (
  <div
    className="thumbHolder"
    style={{
      width: (props.w * 250) / props.h,
      flexGrow: Math.ceil((props.w / props.h) * 800)
      //maxHeight: 320
    }}
  >
    <Link
      to={`/g/${props.filter.toLowerCase()}/${props.slug}`}
      onClick={() =>
        props.history.replace({ state: { fromGallery: props.slug } })
      }
      id={props.slug}
    >
      <img src={props.img.src} alt="" className="thumb" />
    </Link>
  </div>
);

const Items = ({ filter, photos, containerWidth, history }) =>
  filtered({ filter, photos }).map((element, key) => {
    const img = new Image();
    img.src = `/perch/resources/${element.thumbNailPath}`;
    img.onload = () => {
      if (document.getElementById(element.slug) !== null) {
        document.getElementById(element.slug).classList.add("loaded");
      }
    };

    return (
      <ImageHolder
        photos={photos}
        key={key}
        thumbNailPath={img.src}
        slug={element.slug}
        w={element.w}
        h={element.h}
        filter={filter}
        containerWidth={containerWidth}
        img={img}
        history={history}
      />
    );
  });

//const imagesLoadedOptions = { background: ".tileLoading" };

class Photos extends React.Component {
  componentDidMount() {
    //console.log("mounting");
    if (
      this.props.location.state &&
      document.getElementById(this.props.location.state.fromGallery) &&
      !isNaN(
        parseInt(
          document
            .getElementById(this.props.location.state.fromGallery)
            .offsetParent.getBoundingClientRect().top
        )
      )
    ) {
      //console.log("scrolling");
      window.scrollTo(
        0,
        parseInt(
          document
            .getElementById(this.props.location.state.fromGallery)
            .offsetParent.getBoundingClientRect().top
        ) - 140
      );
      this.props.history.replace({ state: { fromGallery: null } });
    }
  }
  render() {
    //console.log("render");
    return (
      <animated.div style={this.props.style}>
        <Helmet>
          <title>Ross Brown Photographer</title>
        </Helmet>
        <Filters />
        <div className="thumbs">
          <Items
            filter={
              this.props.match.params.filter
                ? this.props.match.params.filter.charAt(0).toUpperCase() +
                  this.props.match.params.filter.slice(1)
                : "all"
            }
            photos={this.props.photos}
            containerWidth={this.props.containerWidth}
            style={{ width: "100%" }}
            history={this.props.history}
          />
        </div>
      </animated.div>
    );
  }
}

//const Photos = withSize(Page, "containerWidth");
export default Photos;
