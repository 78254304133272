import React from "react";
import { Helmet } from "react-helmet";
import { animated } from "react-spring/renderprops";

const Bio = (props) => (
  <animated.div className="bioPage" style={{ ...props.style }}>
    <Helmet>
      <title>About Ross Brown Photographer</title>
    </Helmet>
    {window.scrollTo(0, 0)}
    <figure className="rossPhoto">
      <img
        src="./img/ross-brown-photographer-2019.jpg"
        alt="Ross Brown, Photographer."
      />
    </figure>
    <div className="bioBody">
      <h1>About Ross</h1>
      <p>
        Ross was born a small town boy with big aspirations. From a very young
        age he knew he wanted to be a Photographer and he worked tirelessly
        every day after school to save enough money to buy his first range
        finder camera at the age of 13.
      </p>
      <p>
        Later that year he remembers a family holiday traveling around the South
        Island of New Zealand where his parents watched and waited patiently for
        hours while he studied the light, moved this way and that, stumbled over
        logs whilst looking through his viewfinder, waded out into roaring
        rivers and climbed glaciers to find the perfect angle.
      </p>
      <p>
        He returned home with nearly 600 images shot on film and needing to go
        to the lab for processing. His obsession had begun.
      </p>
      <p>
        Needless to say Ross had to keep his after school job to help pay for
        his passion for some time to come.
      </p>
      <p>
        Ross was grateful for such early freedom to explore, experience, and
        create. His life was changed forever.
      </p>
      <p>
        Things are much the same these days. He still has the same strong work
        ethic and passion.
      </p>
      <p>
        Ross won his first gold Advertising award whilst he was still an
        assistant and was soon receiving calls from Agencies asking him to shoot
        campaigns for them.
      </p>
      <p>
        His focus nowadays is Advertising and Design, where he works within a
        wide range of styles and techniques, covering subject matter as diverse
        as Landscape, Portraiture, Sport, Animals, Still life and Conceptual
        work.
      </p>
      <p>
        His images are a mix of contemporary and classical, they are inherently
        elegant, and meticulous in detail. He combines a mastery of light and
        form, mood and atmosphere, with a strong emotive quality and sensitivity
        to his subject matter. These factors along with his beautiful colour
        grades and carefully crafted compositions create his signature style.
      </p>
      <p>
        He possesses a high degree of technical ability and is particularly
        skilled at producing multiple composite images. Ross believes that every
        job should be carefully crafted and works very closely with his
        retouchers and CGI artists, always following a job through to
        completion.
      </p>
      <p>
        Within the industry he is known for his ability to stay calm under
        pressure even in the most trying of circumstances.
      </p>
      <p>
        Ross's work has won many prestigious industry awards both Nationally and
        Internationally, including a Grand Prix and Gold Lion at Cannes. He is
        the double recipient of the highest accolade in Advertising Photography
        in New Zealand, the Brian Brake Memorial Award for Photographic
        excellence, and has won 'Best in Show' and Gold at AXIS, and won 5 gold
        AIPA awards. One of his photographs is hanging in the Musee du Quai
        Branley in Paris.
      </p>
      <p>
        For many years now his images have been selected for Lurzers Archives
        200 Best Advertising Photographers, having also been published in
        Lurzers Archive Photography Annuals and many other publications.
      </p>
      <p>
        Ross has travelled extensively throughout Africa, Asia, China, India,
        Europe and North America and currently divides his work time between LA,
        New York and New Zealand.
      </p>
      <p>
        Aside from his work and precious family time his passions include art,
        architecture, travel, snow boarding, paddle boarding, fly fishing and
        boating.
      </p>
      <h2>Clients</h2>
      <p>
        Absolut
        <br />
        Schweppes
        <br />
        Coca Cola
        <br />
        Stella Artois
        <br />
        Corona
        <br />
        Speight’s Beer
        <br />
        42 Below Vodka
        <br />
        Steinlager Beer
        <br />
        Tui Beer
        <br />
        Adidas
        <br />
        Asics
        <br />
        All Blacks
        <br />
        Toyota
        <br />
        Honda
        <br />
        Hyundai
        <br />
        Intel
        <br />
        Fuji Xerox
        <br />
        Ecoya
        <br />
        Royal New Zealand Ballet
        <br />
        ANZ Bank
        <br />
        Standard Chartered Bank
        <br />
        ASB Bank
        <br />
        Abbott’s Bread
        <br />
        Olympia
        <br />
        Coast Guard NZ
        <br />
        Air New Zealand
        <br />
        World Press Photo
        <br />
        Appleton’s Rum
        <br />
        PlayStation
      </p>
      <h2>Awards</h2>
      <p>
        Lürzer's Archive 200 Best Advertising Photographers Worldwide: 2006/07,
        2010/11, 2012/13, 2014/15
        <br />
        Finalist 23rd Annual Wallace Art Awards
        <br />
        Brian Brake Award for photographic excellence
        <br />
        Cannes Grand Prix
        <br />
        Cannes Gold Lion
        <br />
        One Show bronze
        <br />
        One Show merit
        <br />
        One Show silver
        <br />
        Award silver pencil
        <br />
        Award bronze
        <br />
        Axis best in show
        <br />
        Gold Axis
        <br />5 gold AIPA awards
      </p>
    </div>
  </animated.div>
);

export default Bio;
