import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Transition, config } from "react-spring/renderprops";

import Splash from "./Splash";

import Photos from "./Photos";
import Motion from "./Motion";
import Cgi from "./Cgi";
import Bio from "./Bio";
import Contact from "./Contact";
// import NoMatch from "./404";

import Carousel from "./Gallery";

import Nav from "./Nav";

import "./App.scss";

const API = "/api/homepage-images-api.php";

const mql = window.matchMedia("(max-width: 751px)");

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [],
      navOpen: !mql.matches,
    };

    this.toggleNav = this.toggleNav.bind(this);
  }

  screenTest = (e) => {
    if (e.matches) {
      /* the viewport is 600 pixels wide or less */
      if (this.state.navOpen) {
        this.toggleNav();
      }
    } else {
      /* the viewport is more than than 600 pixels wide */
      if (!this.state.navOpen) {
        this.toggleNav();
      }
    }
  };

  toggleNav = (close) =>
    this.setState((state) => ({ navOpen: !state.navOpen }));

  componentDidMount() {
    mql.addListener(this.screenTest);

    fetch(API)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Image data didn't load");
        }
      })
      .then((data) => this.setState({ photos: data }))
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  render() {
    return (
      <Router>
        <Route
          render={({ location }) => (
            <div id="App">
              <div className="paddedPage">
                <Transition
                  native
                  items={location}
                  //keys={location => location.pathname}
                  keys={
                    location.pathname.split("/")[1] !== "g"
                      ? null
                      : (location) => location.pathname
                  }
                  config={config.default}
                  from={{ opacity: 0 }}
                  enter={{ opacity: 1 }}
                  leave={{
                    opacity: 0,
                    //position: "fixed"
                  }}
                >
                  {(location) => (style) =>
                    (
                      <Switch location={location}>
                        <Route
                          render={(props) =>
                            location.pathname.split("/")[1] !== "g" &&
                            location.pathname.split("/")[1] !== "" ? (
                              <Nav
                                style={style}
                                location={location}
                                open={this.state.navOpen}
                                toggleNav={this.toggleNav}
                                mql={mql}
                              />
                            ) : null
                          }
                        />
                      </Switch>
                    )}
                </Transition>

                <div className="content">
                  <Transition
                    native
                    items={location}
                    keys={
                      // location.pathname.split("/")[1] === "c"
                      //   ? null
                      //   :
                      (location) => location.pathname
                    }
                    config={{ tension: 285, friction: 20, precision: 0.1 }}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      paddingBottom: 40,
                    }}
                  >
                    {(location) => (style) =>
                      (
                        <Switch location={location}>
                          <Route path="/gallery/">
                            <Redirect to="/c/all" />
                          </Route>
                          <Route
                            path="/c/:filter"
                            render={(props) => (
                              <Photos
                                {...props}
                                photos={this.state.photos}
                                style={style}
                                location={location}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/motion"
                            render={(props) => (
                              <Motion {...props} style={style} />
                            )}
                          />
                          <Route
                            exact
                            path="/cgi"
                            render={(props) => <Cgi {...props} style={style} />}
                          />
                          <Route
                            exact
                            path="/bio"
                            render={(props) => <Bio {...props} style={style} />}
                          />
                          <Route
                            exact
                            path="/contact"
                            render={(props) => (
                              <Contact {...props} style={style} />
                            )}
                          />
                          {/* <Route
                          render={props => <NoMatch {...props} style={style} />}
                        /> */}
                        </Switch>
                      )}
                  </Transition>
                </div>
              </div>
              <Transition
                native
                items={location}
                // keys={location => location.pathname}
                keys={
                  location.pathname.split("/").filter((a) => a)[0] +
                  location.pathname.split("/").filter((a) => a)[1]
                }
                config={config.slow}
                from={{ opacity: -1 }}
                enter={{ opacity: 1 }}
                leave={{
                  opacity: 0,
                  position: "fixed",
                  top: 0,
                  width: "100%",
                }}
              >
                {(location) => (style) =>
                  (
                    <Switch location={location}>
                      <Route
                        location={location}
                        exact
                        path="/"
                        render={(props) =>
                          this.state.photos.length !== 0 ? (
                            <Splash
                              {...props}
                              photos={this.state.photos}
                              style={style}
                            />
                          ) : null
                        }
                      />
                      <Route
                        location={location}
                        path="/g/:filter/:ref"
                        render={(props) =>
                          this.state.photos.length !== 0 ? (
                            <Carousel
                              {...props}
                              photos={this.state.photos}
                              style={style}
                            />
                          ) : null
                        }
                      />
                    </Switch>
                  )}
              </Transition>
            </div>
          )}
        />
      </Router>
    );
  }
}

export default App;
