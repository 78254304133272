import React from "react";
import { NavLink, Link } from "react-router-dom";

//import { ReactComponent as Logo } from "./svg/logoBorderless.svg";
import { ReactComponent as Menu } from "./svg/menu.svg";
import { ReactComponent as Close } from "./svg/close.svg";

import { Spring, animated } from "react-spring/renderprops";

const Nav = ({ location, style, open, toggleNav, mql }) => (
  <animated.nav className="mainNav" style={style}>
    <div className="navTop">
      {location.pathname.split("/")[1] === "c" ? (
        <div className="logoBorder">
          {/* <Logo width={140} /> */}
          <span className="logo">Ross Brown.</span>
        </div>
      ) : (
        <Link
          to="/c/all"
          onClick={open && mql.matches ? () => toggleNav("close") : null}
        >
          <div className="logoBorder">
            {/* <Logo width={140} /> */}
            <span className="logo">Ross Brown.</span>
          </div>
        </Link>
      )}
      <div className="menuButton">
        <button onClick={toggleNav}>
          {open ? <Close width={20} /> : <Menu width={20} />}
        </button>
      </div>
    </div>
    <Spring
      native
      force
      config={{ tension: 5000, friction: 500, precision: 0.1 }}
      from={{
        height: open ? 0 : "auto",
        // marginTop: open ? -5 : 20,
        // marginBottom: open ? -10 : 0,
        opacity: open ? 0 : 1
      }}
      to={{
        height: open ? "auto" : 0,
        // marginTop: open ? 20 : -5,
        // marginBottom: open ? 0 : -10,
        opacity: open ? 1 : 0
      }}
    >
      {props => (
        <animated.ul style={props}>
          <li>
            {location.pathname.split("/")[1] === "c" ? (
              <span className="active">Photography</span>
            ) : (
              <NavLink to="/c/all" onClick={mql.matches ? toggleNav : null}>
                Photography
              </NavLink>
            )}
          </li>
          <li>
            <NavLink to="/motion" onClick={mql.matches ? toggleNav : null}>
              Motion
            </NavLink>
          </li>
          <li>
            <NavLink to="/bio" onClick={mql.matches ? toggleNav : null}>
              Bio
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={mql.matches ? toggleNav : null}>
              Contact
            </NavLink>
          </li>
        </animated.ul>
      )}
    </Spring>
  </animated.nav>
);

export default Nav;
