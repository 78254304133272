import React, { Component } from "react";

import Flickity from "react-flickity-component";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { animated } from "react-spring/renderprops";
import { Helmet } from "react-helmet";

import { ReactComponent as PrevArrow } from "./svg/prevArrow.svg";
import { ReactComponent as NextArrow } from "./svg/nextArrow.svg";
import { ReactComponent as Close } from "./svg/close.svg";

const SlideHolder = image => (
  <div
    className="slideHolder"
    id={image.slug}
    data-title={image.title}
    data-slug={image.slug}
    //style={{ backgroundImage: `url(${image.fullSizePath})` }}
  >
    <img
      data-flickity-lazyload-src={image.fullSizePath}
      alt={image.title}
      id={`${image.slug}Img`}
    />
  </div>
);

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: this.props.photos,
      currentSlideTitle: "",
      currentSlideSlug: ""
    };
    this.keyDownFunction = this.keyDownFunction.bind(this);
    this.keyUpFunction = this.keyUpFunction.bind(this);
  }

  filtered = ({ filter, photos }) =>
    !filter || filter === "All"
      ? photos
      : photos.filter(item => item.cats && item.cats.includes(filter));

  Items = (filter, photos) =>
    this.filtered({ filter, photos }).map((element, key) => {
      return (
        <SlideHolder
          photos={photos}
          key={key}
          fullSizePath={`/perch/resources/${element.fullSizePath}`}
          title={element.title}
          slug={element.slug}
          w={element.w}
          h={element.h}
          containerWidth={880}
          filter={filter}
        />
      );
    });

  flickityOptions = {
    initialIndex:
      !this.props.match.params.filter ||
      this.props.match.params.filter === "all"
        ? this.props.photos.findIndex(
            item => item.slug === this.props.match.params.ref
          )
        : this.props.photos
            .filter(
              item =>
                item.cats &&
                item.cats.includes(
                  this.props.match.params.filter.charAt(0).toUpperCase() +
                    this.props.match.params.filter.slice(1)
                )
            )
            .findIndex(item => item.slug === this.props.match.params.ref),

    wrapAround: true,
    // selectedAttraction: 0.005,
    // friction: 0.12,
    accessibility: true,
    setGallerySize: false,
    prevNextButtons: false,
    pageDots: false,
    lazyLoad: 2
  };

  keyDownFunction(event) {
    if (event.key === "Escape") {
      window.history.back();
    }
    if (
      event.key === "Shift" ||
      event.key === "Control" ||
      event.key === "Alt" ||
      event.key === "Meta"
    ) {
      this.flkty.options.accessibility = false;
    }
  }

  keyUpFunction(event) {
    if (
      event.key === "Shift" ||
      event.key === "Control" ||
      event.key === "Alt" ||
      event.key === "Meta"
    ) {
      this.flkty.options.accessibility = true;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownFunction, false);
    document.removeEventListener("keyup", this.keyUpFunction, false);
  }

  componentDidMount = () => {
    document.addEventListener("keyup", this.keyUpFunction, false);
    document.addEventListener("keydown", this.keyDownFunction, false);

    this.flkty.on("change", () => {
      this.setState({
        currentSlideTitle: this.flkty.selectedElement.dataset.title
      });
      //add a check that we're still in the gallery
      if (this.props.history.location.pathname.split("/")[1] === "g") {
        this.props.history.replace(
          `/g/${this.props.match.params.filter +
            "/" +
            this.flkty.selectedElement.id}`
        );
      }

      //
      //this.props.history.push(`/g/${this.flkty.selectedIndex}`);
    });
    this.flkty.on("ready", () => {
      this.setState({
        currentSlideTitle: this.flkty.selectedElement.dataset.title,
        currentSlideSlug: this.flkty.selectedElement.dataset.slug
      });
      this.flkty.resize();
      this.flkty.focus();
    });
  };

  render() {
    return (
      <animated.div className="galleryPage" style={this.props.style}>
        <Helmet>
          <title>{this.state.currentSlideTitle} - Ross Brown</title>
        </Helmet>
        <div className="galleryHeader">
          <Link
            to={`/c/${this.props.match.params.filter}`}
            className="noUnderline"
          >
            <span className="logo galleryLogo">Ross Brown.</span>
          </Link>
          <div className="slideTitle">{this.state.currentSlideTitle}</div>
          <div className="galleryButtons">
            <button onClick={() => this.flkty.previous()}>
              {" "}
              <PrevArrow height={20} width={11} focusable="false" />
            </button>
            <button onClick={() => this.flkty.next()}>
              <NextArrow height={20} width={11} focusable="false" />
            </button>
            <Link
              to={{
                pathname: `/c/${this.props.match.params.filter}`,
                state: { fromGallery: this.state.currentSlideSlug }
              }}
              className="button"
            >
              <Close height={20} width={20} focusable="false" />
            </Link>
          </div>
        </div>
        <Flickity
          className={"carousel"} // default ''
          //elementType={"div"} // default 'div'
          options={this.flickityOptions} // takes flickity options {}
          disableImagesLoaded={true} // default false
          //reloadOnUpdate={false}
          flickityRef={c => (this.flkty = c)}
        >
          {this.Items(
            this.props.match.params.filter
              ? this.props.match.params.filter.charAt(0).toUpperCase() +
                  this.props.match.params.filter.slice(1)
              : "All",
            this.props.photos
          )}
        </Flickity>
      </animated.div>
    );
  }
}

export default withRouter(Carousel);
